import { Footer } from "@/components/Footer/Footer";
import { Loader } from "@/components/Loader/Loader";
import { Navbar } from "@/components/Navbar/Navbar";

import { EnsignBeers } from "@/containers/Home/EnsignBeers/EnsignBeers";
import { FactoryBanner } from "@/containers/Home/FactoryBanner/FactoryBanner";
import { HistoryBanner } from "@/containers/Home/HistoryBanner/HistoryBanner";
import { HomeHero } from "@/containers/Home/HomeHero/HomeHero";
import { MerchBanner } from "@/containers/Home/MerchBanner/MerchBanner";
import { useAppState } from "@/pages/context/AppProvider";
import { IndexPageRef } from "@/types";
import { getHomeData, getMerchData } from "@/utils/backFunctions";
import { GetServerSideProps } from "next";
import styled from "styled-components";

const HomeWrapper = styled.div<{ cartOpen: boolean }>`
    background: ${(props) => props.theme.color.green};
    width: 100%;
    min-height: 100vh;
`;

export default function Home({ homeData, merchData }: { homeData: any; merchData: any; ref: IndexPageRef }) {
    const { beers, cartOpen } = useAppState();

    return (
        <HomeWrapper cartOpen={cartOpen}>
            <Navbar data={homeData} />
            {!homeData || !merchData || !beers ? (
                <Loader />
            ) : (
                <>
                    <HomeHero data={homeData} />
                    <EnsignBeers data={homeData} beers={beers} />
                    <FactoryBanner data={homeData} />
                    <MerchBanner data={merchData} />
                    <HistoryBanner data={homeData} />
                    <Footer data={homeData} />
                </>
            )}
        </HomeWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
    const homeData = await getHomeData(locale as string);
    const merchData = await getMerchData(locale as string);
    return {
        props: {
            homeData: homeData?.data?.attributes || null,
            merchData: merchData || null,
        },
    };
};
